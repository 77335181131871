<template>
  <div>
    <app-user-info v-if="physician" class="border rounded p-75 mb-1" variant="success" :alt_title="$physicianRole(physicianRole)" icon="" :title="physician.fullname" />
    <div v-else :class="{ 'border-danger': error }" class="border rounded p-75">
      <div v-if="!makeAssignment" class="d-flex justify-content-between align-items-center">
        <h5 class="m-0">Henüz Atama Yapılmamıştır</h5>
        <app-icon-button variant="gradient-danger" icon="PlusIcon" @click="getPhysicianOptions(physicianRole), (makeAssignment = true)" />
      </div>
      <div v-else>
        <validation-observer ref="assignmentform">
          <app-select-input
            v-model="selectedPhysician"
            class="mb-0"
            :options="physicianOptions"
            select_label="auth.fullname"
            name="Uzman"
            placeholder="Seçiniz.."
            :label="$physicianRole(physicianRole) + ':'"
            :reduce="(option) => option._id"
            rules="required"
          />
        </validation-observer>

        <b-row style="gap: 5px 0">
          <b-col md="6">
            <b-button @click="newAssignment" variant="gradient-success" class="p-50" style="font-size: 13px" block> Atama Yap </b-button>
          </b-col>
          <b-col md="6">
            <b-button @click="makeAssignment = false" variant="gradient-danger" class="p-50" style="font-size: 13px" block> İptal Et </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <small class="text-danger">{{ error }}</small>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import graphqlRequest from "@/utils/graphqlRequest";

export default {
  components: { ValidationObserver },
  props: {
    company: String,
    branch: String,
    physicianRole: String,
    error: { type: String },
    value: String | Object,
  },
  data() {
    return {
      selectedPhysician: null,
      physicianOptions: [],
      makeAssignment: false,
      physician: null,
    };
  },
  watch: {
    physicianRole(val) {
      if (this.makeAssignment) {
        this.getPhysicianOptions(val);
        this.selectedPhysician = null;
      }
    },
    value(val) {
      this.physician = val;
    },
    physician(val) {
      if (val) this.$emit("input", this.physician);
    },
  },
  mounted() {
    this.getAssignment();
  },
  methods: {
    getPhysicianOptions() {
      this.$store
        .dispatch("getPhysicianOptions", this.physicianRole)
        .then((options) => {
          this.physicianOptions = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAssignment() {
      graphqlRequest("/assignments", {
        query: `{ assignment(company: "${this.company}", branch:"${this.branch}", physicianRole:"${this.physicianRole}", isAssignmentActive: true){ physician{_id fullname} } }`,
      })
        .then(({ assignment }) => {
          if (assignment) {
            this.physician = assignment?.physician;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newAssignment() {
      this.$validate(this.$refs.assignmentform, () => {
        this.$axios
          .post(
            "/rest-assignments/create",
            {
              physician: this.selectedPhysician,
              company: this.company,
              branch: this.branch,
            },
            { loading: "sidebar" }
          )
          .then((result) => {
            this.physician = result?.data?.physician;
            this.makeAssignment = false;
          })
          .catch((err) => {
            emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
  },
};
</script>

<style></style>
