<template>
  <b-custom-tabs :tabs="tabs" lazy />
</template>

<script>
import CompletedVisits from "./CompletedVisits.vue";
import NotCompletedVisits from "./NotCompletedVisits.vue";
import PlanedVisits from "./PlanedVisits.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Planlananlar", component: PlanedVisits },
        { title: "Yapılanlar", component: CompletedVisits },
        { title: "Yapılmayanlar", component: NotCompletedVisits },
      ],
    };
  },
};
</script>

<style></style>
