<template>
  <app-calendar :data="visits" @event:click="showDetails" @dates:set="datesSet" />
</template>

<script>
export default {
  props: {
    query: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      visits: [],
      dates: {},
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/visits/list/calendar", {
          params: {
            ...this.query,
            ...this.dates,
          },
          loading: "table",
        })
        .then((result) => {
          this.visits = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    datesSet(dates) {
      this.dates = dates;
      this.getData();
    },
    showDetails(item) {
      this.$router.push({ name: "visit-detail", params: { id: item?._id } });
    },
  },
  watch: {
    query: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
