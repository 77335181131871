<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer #default="{ errors }" ref="visitform">
      {{ checkErrors(errors) }}
      <company-select v-if="!selectedCompany" @input="handleSelectChange" :reduce="(option) => option._id" v-model="company" required />
      <branch-select @input="handleSelectChange" :reduce="(option) => option._id" :company="company" v-model="branch" required />
      <div v-if="company && branch">
        <hr />
        <b-form-checkbox-group v-model="selectedOptions" class="d-flex gap-10 justify-content-center">
          <b-form-checkbox @change="onPhysicianChexboxChange" v-for="item in physicianOptions" :key="item.key" :value="item.key">
            {{ item.title }}
          </b-form-checkbox>
        </b-form-checkbox-group>
        <hr v-if="visits.length" />
        <app-collapse type="border" class="rounded" title-size="sm">
          <app-collapse-item :ref="item.physicianRole + 'Collapse'" v-for="(item, key) in visits" :key="key" :title="roleTitles[item.physicianRole]">
            <div class="mb-1">
              <ValidationProvider name="Atama" :vid="'assignment-' + item.physicianRole" #default="{ errors }" rules="required">
                <physician-card v-model="item.physician" :company="item.company" :branch="item.branch" :physicianRole="item.physicianRole" :error="errors[0]" />
              </ValidationProvider>
            </div>
            <app-date-input
              v-model="item.startDate"
              :id="item.physicianRole"
              name="Planlanan Ziyaret Tarihi"
              label="Planlanan Ziyaret Tarihi:"
              placeholder="Tarih Seçiniz..."
              rules="required"
            />
          </app-collapse-item>
        </app-collapse>
      </div>
      <hr />
      <app-button @click="create" text="Oluştur" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import physicianCard from "@/views/Visits/createSidebar/physicianCard.vue";
export default {
  components: { ValidationObserver, ValidationProvider, physicianCard },
  data() {
    return {
      company: null,
      branch: null,
      physicianOptions: [
        { key: "wsExpert", title: "İGU" },
        { key: "physician", title: "İH" },
        { key: "dsp", title: "DSP" },
      ],
      visits: [],
      selectedOptions: [],
      roleTitles: {
        wsExpert: "İGU Planla",
        physician: "İH Planla",
        dsp: "DSP Planla",
      },
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
  },
  methods: {
    setData() {
      return this.visits.map((item) => ({
        ...item,
        startDate: new Date(item.startDate),
        physician: item.physician?._id,
      }));
    },
    create() {
      this.$validate(this.$refs.visitform, () => {
        this.visits.length && this.$store.dispatch("createManyVisit", { data: this.setData(), actionType: this.selectedCompany ? "setNewManyVisits" : "setNewManyPlanedVisits" });
      });
    },
    handleSelectChange() {
      this.selectedOptions = [];
      this.visits = [];
    },
    onPhysicianChexboxChange(val) {
      this.visits = val.map((item) => this.setCheckBoxValues(item));
    },
    checkErrors(errors) {
      ["wsExpert", "physician", "dsp"].forEach((item) => {
        const error = errors[item]?.length ?? errors["assignment-" + item]?.length ?? null;
        if (error) {
          this.$refs[item + "Collapse"][0]?.updateVisible(true);
        }
      });
    },
    setCheckBoxValues(key) {
      const checkExist = this.visits.find((item) => item.physicianRole === key);
      return checkExist
        ? checkExist
        : {
            company: this.company,
            branch: this.branch,
            physician: null,
            startDate: null,
            physicianRole: key,
          };
    },
  },
  mounted() {
    if (this.selectedCompany) {
      this.company = this.selectedCompany;
    }
  },
};
</script>

<style></style>
