<template>
  <visit-layout>
    <template #list>
      <visit-list :fields="fields" :query="query" getterKey="completedVisits" actionType="setCompletedVisits" />
    </template>
    <template #calendar>
      <visit-calendar :query="query" />
    </template>
  </visit-layout>
</template>

<script>
import VisitLayout from "./visitLayout/VisitLayout.vue";
import VisitList from "./visitLayout/VisitList.vue";
import VisitCalendar from "./visitLayout/VisitCalendar.vue";

export default {
  components: { VisitLayout, VisitList, VisitCalendar },
  data() {
    return {
      fields: [
        { key: "company", label: "Firma" },
        { key: "branch.name", label: "Sicil Adı" },
        { key: "physician", label: "Görevli Personel" },
        {
          key: "startDate",
          label: "Ziyaret Tarihi",
          formatDate: true,
        },
        {
          key: "createdAt",
          label: "Oluşturma Tarihi",
          formatDateTime: true,
        },
      ],
    };
  },
  computed: {
    query() {
      return {
        "status.key": "completed",
      };
    },
  },
};
</script>

<style></style>
